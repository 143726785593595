.gallery_image_content {
  height: 500px;
  cursor: pointer;
  overflow: hidden;
  /* Removing this will break the effects */
}

.gallery_image_content:hover .gallery_image_content-s {
  width: 20%;
}

.gallery_image_content img {
  display: block;
  height: inherit;
  width: 100%;
  object-fit: cover;
}

/*Zoom Out*/

.gallery_image_content.zoomOut img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.gallery_image_content.zoomOut:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.gallery_image_content-s {
  width: 0;
  height: inherit;
  background: rgba(215, 204, 219, 0.89);
  position: absolute;
  margin-top: -500px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.4s ease;
  text-align: center;
  /* Add This*/
}

.team-ing81-holder {
  text-align: center;
}

.team-ing81-holder div {
  display: inline-block;
  max-height: 15%;
  max-width: 15%;
  margin-left: 2%;
  margin-right: 2%;
}

.team-ing81-holder {
  padding-top: 5%;
  padding-bottom: 5%;
}

.team-ing81-holder p {
  margin: 0;
}

.gallery_image_content-s h5 {
  font-size: large;
}

